import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ReturnList from "../common/list";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { UserUtils } from "../../../app/data/user/userUtils";
import { Routes } from "../../../app/route/RoutesConfig";
import "../bol.scss";

const CombinedList: React.FC<{}> = (props) => {
  const userState: UserState = useSelector(userSelector);
  const history = useHistory();

  useEffect(() => {
    if (UserUtils.hasFeatureDisabled(userState.profile, "RETURNS")) {
      history.push(Routes.home);
    }
  }, [history, userState.profile]);

  return (
    <ContentContainer title="Returns">
      <ReturnList defaultTypes={["RETURN"]} />
    </ContentContainer>
  );
};

export default CombinedList;
