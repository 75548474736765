import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import "./../warehouse.scss";
import Table from "../../../ui-components/table/table";
import FiltersToggle from "../../../ui-components/filters-toggle/filtersToggle";
import InventoryListFilter from "./inventoryListFilter";
import inventoryListColumns from "./inventoryListColumns";
import inventoryTransactionsColumns from "./inventoryTransactionsColumns";
import { InventoryItemModel } from "../../../app/data/warehouse/models";
import InventoryState from "../../../slices/warehouse/inventory/InventoryState";
import {
  inventorySelector,
  getInventoryList,
  getInventoryTransactions
} from "../../../slices/warehouse/inventory/inventorySlice";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";

const WarehouseInventoryList: React.FC<{}> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const inventoryState: InventoryState = useSelector(
    inventorySelector
  );
  const dispatch = useDispatch();

  const onRowClick = (row: InventoryItemModel) => {
    if (inventoryState.transactions.length > 0) return;
    if (userState.activeSubAccount?.id) {
      dispatch(getInventoryList(userState.activeSubAccount.id, {
        item: row.item,
        invoiceNumber: "",
        lastIds: ""
      }));
      dispatch(getInventoryTransactions(userState.activeSubAccount.id, row.item, ""));      
    }
  };

  const onListInfiniteScroll = () => {
    userState.activeSubAccount?.id && dispatch(getInventoryList(userState.activeSubAccount.id, inventoryState.listRequest));
  };

  const onTransactionsInfiniteScroll = () => {
    userState.activeSubAccount?.id
    && inventoryState.listRequest.item
    && dispatch(getInventoryTransactions(userState.activeSubAccount.id, inventoryState.listRequest.item, inventoryState.transactionsLastIds || ""));
  };  

  useEffect(() => {
    if (!inventoryState.listRequestFailed && !inventoryState.transactionsRequestFailed) return;
    toast.error(inventoryState.listRequestError || inventoryState.transactionsRequestError || "Error", { autoClose: 4000 });
  }, [
    inventoryState.listRequestFailed,
    inventoryState.listRequestError,
    inventoryState.transactionsRequestFailed,
    inventoryState.transactionsRequestError
  ]);

  return (
    <ContentContainer
      titleComponent={
        <ContentContainerToolbar title="Inventory">
          <FiltersToggle />
        </ContentContainerToolbar>
      }
      header={
        <InventoryListFilter />
      }
    >
      <div className="xgs-warehouse__inventory">
        {!inventoryState.listRequestFailed && (
          <>
            <div
              className={inventoryState.transactions.length > 0 ? "xgs-warehouse__inventory__drilldown-list" : ""}
              style={{ height: inventoryState.listRequest?.item && ((inventoryState.transactions.length > 0) || inventoryState.transactionsInitialRequestStarted) ? "30vh" : "auto" }}>
              <Table
                isLoading={inventoryState.listInitialRequestStarted}
                columns={inventoryListColumns(userState.activeSubAccount?.accountNumber, onRowClick)}
                data={inventoryState.list}
                autoHeight={inventoryState.transactions.length > 0}
                infiniteScroll={true}
                infiniteScrollLoading={inventoryState.listRequestStarted}
                infiniteScrollHasNext={!inventoryState.listRequestedAll}
                onInfiniteScroll={onListInfiniteScroll}
                rowHeight={60}
                minTableHeight={420}
              />
            </div>
          </>
        )}
        {inventoryState.listRequest?.item && ((inventoryState.transactions.length > 0) || inventoryState.transactionsInitialRequestStarted) && (
          <>
            <div className="xgs-warehouse__inventory__transactions__header">Transactions</div>
            <Table
              isLoading={inventoryState.transactionsInitialRequestStarted}
              columns={inventoryTransactionsColumns(userState.activeSubAccount?.accountNumber)}
              data={inventoryState.transactions}
              infiniteScroll={true}
              infiniteScrollLoading={inventoryState.transactionsRequestStarted}
              infiniteScrollHasNext={!inventoryState.transactionsRequestedAll}
              onInfiniteScroll={onTransactionsInfiniteScroll}
              rowHeight={50}
              minTableHeight={420}
            />
          </>
        )}
      </div>
    </ContentContainer>
  );
});

export default WarehouseInventoryList;
