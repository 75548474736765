import React, { useState, useEffect, useMemo, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import LabeledDateRangeInput from "../../../ui-components/molecules/labeled-inputs/labeled-date-range-input/labeledDateRangeInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, {
  ButtonThemes,
} from "../../../ui-components/button/button";
import { getTransactionsList } from "../../../slices/warehouse/transactions/transactionsSlice";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import FiltersToggleState from "../../../slices/filters-toggle/FiltersToggleState";
import { filtersToggleSelector } from "../../../slices/filters-toggle/filtersToggleSlice";
import "./../warehouse.scss";

const TransactionsListFilter: React.FC<{}> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const filtersToggleState: FiltersToggleState = useSelector(filtersToggleSelector);
  const defaultFrom = moment().subtract(1, "months").format("MM/DD/YYYY");
  const defaultTo = moment().format("MM/DD/YYYY");
  const [from, setFromDate] = useState<string>(defaultFrom);
  const [to, setToDate] = useState<string>(defaultTo);
  const dispatch = useDispatch();

  const accountId = useMemo(() => {
    return userState.activeSubAccount?.id;
  }, [userState]);

  const onSearchSubmit = () => {
    accountId && from && to && dispatch(getTransactionsList(accountId, {
      from: from.toApiDateFormat(),
      to: to.toApiDateFormat()
    }));
  };

  const onSearchClear = () => {
    setFromDate(defaultFrom);
    setToDate(defaultTo);
    accountId && dispatch(getTransactionsList(accountId, {
      from: defaultFrom.toApiDateFormat(),
      to: defaultTo.toApiDateFormat()
    }));
  };

  const onStartDateChanged = (value: string) => {
    setFromDate(value);
    accountId && value && to && dispatch(getTransactionsList(accountId, {
      from: value.toApiDateFormat(),
      to: to.toApiDateFormat()
    }));
  };

  const onEndDateChanged = (value: string) => {
    setToDate(value);
    accountId && from && value && dispatch(getTransactionsList(accountId, {
      from: from.toApiDateFormat(),
      to: value.toApiDateFormat()
    }));
  };

  useEffect(() => {
    setFromDate(defaultFrom);
    setToDate(defaultTo);
    accountId && dispatch(getTransactionsList(accountId, {
      from: defaultFrom.toApiDateFormat(),
      to: defaultTo.toApiDateFormat()
    }));
  }, [defaultFrom, defaultTo, dispatch, accountId]);

  return (
    <div className={`xgs-warehouse__filter ${filtersToggleState.showFilters ? "xgs-filters-toggle__filters--show" : "xgs-filters-toggle__filters--hide"}`}>
      <div className="xgs-warehouse__filter__controls">
        <div className="xgs-warehouse__filter__controls__item xgs-warehouse__filter__controls__item--height-auto">
          <LabeledDateRangeInput
            label="Dates range:"
            labelMode={LabelModes.column}
            className="xgs-tracking-filter__input"
            start={from}
            end={to}
            onStartChange={onStartDateChanged}
            onEndChange={onEndDateChanged}
          />              
        </div>
        <div className="xgs-warehouse__filter__controls__buttons">
          <Button
            theme={ButtonThemes.blue}
            type="submit"
            disabled={!from || !to}
            onClick={onSearchSubmit}
          >
            Search
          </Button>
          <Button
            theme={ButtonThemes.gray}
            type="button"
            onClick={onSearchClear}
          >
            Clear
          </Button>
        </div>        
      </div>          
    </div>
  );
});

export default TransactionsListFilter;
