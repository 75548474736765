export interface Aging {
  id: string;
  current: number;
  aging1: number;
  aging31: number;
  aging61: number;
  aging91: number;
  aging120: number;
  total: number;
}

export interface IAgingMeta {
  title: string;
  period: string;
  field: string;
}

export const agingPeriods = {
  total: {
    title: "Total",
    period: "_TOTAL",
    field: "total"
  },
  selected: {
    title: "Selected",
    period: "_selected",
    field: ""
  },  
  current: {
    title: "Current",
    period: "_CURRENT",
    field: "current"
  },
  _1_30: {
    title: "1-30",
    period: "_1_30",
    field: "aging1"
  },
  _31_60: {
    title: "31-60",
    period: "_31_60",
    field: "aging31"
  },
  _61_90: {
    title: "61-90",
    period: "_61_90",
    field: "aging61"
  },
  _91_120: {
    title: "91-120",
    period: "_91_120",
    field: "aging91"
  },
  _120_plus: {
    title: "120+",
    period: "_120_PLUS",
    field: "aging120"
  },
};