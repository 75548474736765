import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import "./../warehouse.scss";
import Table from "../../../ui-components/table/table";
import ReceiptsListFilter from "./receiptsListFilter";
import receiptsListColumns from "./receiptsListColumns";
import ReceiptsState from "../../../slices/warehouse/receipts/ReceiptsState";
import {
  receiptsSelector,
  getReceiptsList  
} from "../../../slices/warehouse/receipts/receiptsSlice";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import FiltersToggle from "../../../ui-components/filters-toggle/filtersToggle";

const WarehouseReceiptsList: React.FC<{}> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const receiptsState: ReceiptsState = useSelector(
    receiptsSelector
  );
  const dispatch = useDispatch();

  const onListInfiniteScroll = () => {
    userState.activeSubAccount?.id && dispatch(getReceiptsList(userState.activeSubAccount.id, receiptsState.request));
  };

  useEffect(() => {
    if (!receiptsState.requestFailed) return;
    toast.error(receiptsState.requestError || "Error", { autoClose: 4000 });
  }, [
    receiptsState.requestFailed,
    receiptsState.requestError
  ]);

  return (
    <ContentContainer
      titleComponent={
        <ContentContainerToolbar title="Receipts">
          <FiltersToggle />
        </ContentContainerToolbar>
      }
      header={
        <ReceiptsListFilter />
      }
    >
      <div className="xgs-warehouse__receipts">
        {!receiptsState.requestFailed && (
          <>
            <Table
              isLoading={receiptsState.initialRequestStarted}
              columns={receiptsListColumns(userState.activeSubAccount?.accountNumber)}
              data={receiptsState.list}
              infiniteScroll={true}
              infiniteScrollLoading={receiptsState.requestStarted}
              infiniteScrollHasNext={!receiptsState.requestedAll}
              onInfiniteScroll={onListInfiniteScroll}
              rowHeight={60}
              minTableHeight={420}
            />
          </>
        )}        
      </div>
    </ContentContainer>
  );
});

export default WarehouseReceiptsList;
