import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { exportSelector, getExportLink, resetExportLink } from "../../slices/export/exportSlice";
import ExportState from "../../slices/export/ExportState";
import { invoiceDetailsSelector } from "../../slices/invoice/invoiceSlice";
import { InvoiceDetailsState } from "../../slices";
import XGSIcon from "../../ui-components/icon/xgsIcon";
import XGSIcons from "../../ui-components/icon/xgsIcons";
import Button, { ButtonThemes } from "../../ui-components/button/button";
import Loading from "../../ui-components/loading/loading";
import { toast } from "react-toastify";
import "./downloadPDF.scss";

export interface DownloadPDFProps {
  invoiceNumbers: number | number[] | undefined;
  type: string;
}

const DownloadPDF: React.FC<DownloadPDFProps> = (props) => {
  const exportState: ExportState = useSelector(exportSelector);
  const invoiceDetailsState: InvoiceDetailsState = useSelector(invoiceDetailsSelector);
  const dispatch = useDispatch();

  const downloadPDF = () => {
    if (!props.invoiceNumbers) return;
    if (!exportState.exportLink) {
      dispatch(getExportLink(
        props.invoiceNumbers,
        "pdf",
        Array.isArray(props.invoiceNumbers)
          ? exportState.exportId
          : invoiceDetailsState.invoiceDetails?.exportId,
        onExportSuccess,
        onExportFailed
      ));
    } else {
      onExportSuccess(exportState.exportLink);
    }
  }

  const onExportSuccess = (exportLink: string) => {
    if (!exportLink) return
    const link = document.createElement("a");
    link.href = exportLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onExportFailed = () => {
    toast.error("Failed to export and download PDF");
  }  

  React.useEffect(() => {
    dispatch(resetExportLink());
  }, [dispatch, props.invoiceNumbers]);

  return (
    <>
      <span className="xgs-site__download-pdf__button">
        {!exportState.exportLoading && (
          <>
            {props.type === "single" && (
              <div className="xgs-site__download-pdf__button__single xgs-site__content-container__toolbar__buttons__item">
                <XGSIcon
                  className="xgs-site__download-pdf__button__icon"
                  icon={XGSIcons.faPrint}
                  size="lg"
                  title="Download PDF"
                  onClick={downloadPDF}
                />
                <div className="xgs-site__download-pdf__button__label">Print</div>
              </div>
            )}
            {props.type === "multiple" && (
              <Button
                className="xgs-site__download-pdf__button__multiple"
                theme={ButtonThemes.blue}
                onClick={downloadPDF}
                disabled={(props.invoiceNumbers === undefined) || (Array.isArray(props.invoiceNumbers) && props.invoiceNumbers.length === 0)}
              >
                Download Selected
              </Button>
            )}
          </>
        )}
        {exportState.exportLoading && (
          <div style={{ marginTop: 8 }}>
            <Loading isLoading={true} />
          </div>
        )}
      </span>
    </>
  );
};

export default DownloadPDF;
