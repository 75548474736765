import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ContentContainer from "../../../templates/content-container/contentContainer";
import ContentContainerToolbar from "../../../ui-components/molecules/content-container-toolbar/contentContainerToolbar";
import Table from "../../../ui-components/table/table";
import FiltersToggle from "../../../ui-components/filters-toggle/filtersToggle";
import TransactionsListFilter from "./transactionsListFilter";
import transactionsListColumns from "./transactionsListColumns";
import TransactionsState from "../../../slices/warehouse/transactions/TransactionsState";
import {
  transactionsSelector,
  getTransactionsList  
} from "../../../slices/warehouse/transactions/transactionsSlice";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import "./../warehouse.scss";

const WarehouseTransactionsList: React.FC<{}> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const transactionsState: TransactionsState = useSelector(
    transactionsSelector
  );
  const dispatch = useDispatch();

  const onListInfiniteScroll = () => {
    userState.activeSubAccount?.id && dispatch(getTransactionsList(userState.activeSubAccount.id, transactionsState.request));
  };

  useEffect(() => {
    if (!transactionsState.requestFailed) return;
    toast.error(transactionsState.requestError || "Error", { autoClose: 4000 });
  }, [
    transactionsState.requestFailed,
    transactionsState.requestError
  ]);

  return (
    <ContentContainer
      titleComponent={
        <ContentContainerToolbar title="Transactions">
          <FiltersToggle />
        </ContentContainerToolbar>
      }
      header={
        <TransactionsListFilter />
      }
    >
      <div className="xgs-warehouse__transactions">
        {!transactionsState.requestFailed && (
          <>
            <Table
              isLoading={transactionsState.initialRequestStarted}
              columns={transactionsListColumns(userState.activeSubAccount?.accountNumber)}
              data={transactionsState.list}
              infiniteScroll={true}
              infiniteScrollLoading={transactionsState.requestStarted}
              infiniteScrollHasNext={!transactionsState.requestedAll}
              onInfiniteScroll={onListInfiniteScroll}
              rowHeight={60}
              minTableHeight={420}
            />
          </>
        )}
      </div>
    </ContentContainer>
  );
});

export default WarehouseTransactionsList;
