export const dateFormats = {
  date: "MM-DD-YYYY",
  uiDateShort: "MM/DD/YY",
  uiDate: "MM/DD/YYYY",
  uiDateWeek: "ddd, MM/DD/YY",
  uiDateTime: "MM/DD/YYYY hh:mm a",
  uiDateTimeShort: "MM/DD/YY h:mm A",
  uiTimeShort: "h:mm A",
  apiDate: "YYYY-MM-DD",
  apiDateTime: "YYYY-MM-DD hh:mm:ss a",
  isoDate: "YYYYMMDD",
  uiDateOrdinal: "MMM Do, YYYY"
};

export default dateFormats;