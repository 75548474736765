import React from "react";
import { Link } from "react-router-dom";

const receiptsListColumns = (
  accountNumber: number | null | undefined
) => [
  {
    Header: "Item",
    accessor: "item",
    width: 120
  },
  {
    Header: "Probill",
    accessor: "invoiceNumber",
    width: 90,
    Cell: (cellProps: any) => (
      <Link
        className="xgs-table-link-cell"
        to={`${accountNumber ? "/" + accountNumber : ""}/shipments/${cellProps.value}`}
      >
        {cellProps.value}
      </Link>
    )
  },
  {
    Header: "Date",
    accessor: "date",
    Cell: (cellProps: any) => (
      <>
        { cellProps.value ? cellProps.value.toUiDateFormat() : "" }
      </>
    )
  },
  {
    Header: "BOL",
    accessor: "billOfLading"
  },
  {
    Header: "PO",
    accessor: "poNumber",
    width: 90
  },
  {
    Header: "Description",
    accessor: "description"
  },
  {
    Header: "Sq. Yards",
    accessor: "sqYards",
    width: 90
  },
  {
    Header: "Width",
    accessor: "width",
    width: 90
  },
  {
    Header: "Length",
    accessor: "length",
    width: 90
  }
];

export default receiptsListColumns;
