import React from "react";
import { cn } from "../../../services/common/className";
import { TagColor } from "../../../app/data/common/tagColor";

import "./tag.scss";

export interface TagProps {
  children: any;
  mods?: {
    color?: TagColor;
    uppercase?: boolean;
  };
  mix?: string;  
}

const Tag: React.FC<TagProps> = ({ mods, mix, children }) => {
  return (
    <div className={cn("tag")(mods, mix)}>
      {children}
    </div>
  );
}

export default Tag;