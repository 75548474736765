import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { StandingAppointment } from "./standingAppointment";
import { AccountOpenHoursType } from "../../../app/data/standing-appointment/models";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import styles from "./../../../sass/variables.module.scss";

export const StandingAppointmentTabs: React.FC<any> = (props) => {
  return (
    <>
      <div className="xgs-standing-appointment__heading">Availability Hours</div>
      <div className="xgs-bol__item-form__misc-text">
        <ul>
          <li>
            Non-service days are marked with &nbsp;
            <XGSIcon icon={XGSIcons.faCalendarTimes} color={styles.red} />.
          </li>
          <li>
            Times are displayed in <strong>local time-zone</strong> of the account.
          </li>
        </ul>
      </div>
      <Tabs>
        <TabList>
          <Tab>Business</Tab>
          <Tab>Shipping</Tab>
          <Tab>Receiving</Tab>
        </TabList>
        <TabPanel>
          <StandingAppointment accountId={props.accountId} type={AccountOpenHoursType.BUSINESS} />
        </TabPanel>
        <TabPanel>
          <StandingAppointment accountId={props.accountId} type={AccountOpenHoursType.SHIPPING} />
        </TabPanel>
        <TabPanel>
          <StandingAppointment accountId={props.accountId} type={AccountOpenHoursType.RECEIVING} />
        </TabPanel>
      </Tabs>
    </>
  );
};
