import * as Yup from 'yup';
import moment from 'moment';

export enum DayOfWeek {
  SUNDAY = "SUNDAY",
  MONDAY = "MONDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
  THURSDAY = "THURSDAY",
  FRIDAY = "FRIDAY",
  SATURDAY = 'SATURDAY'
}

export type StandingAppointmentHours = {
  fromTime?: string | null;
  toTime?: string | null;
  isOpen?: boolean;
}

export type StandingAppointmentDays = {
  [key in DayOfWeek]: StandingAppointmentHours
}

export type StandingAppointmentDto = {
  dailyHoursMap: StandingAppointmentDays;
  isUpdatedOnCustomerPortal?: boolean;
  userUpdated?: string;
  dateUpdated?: string;
}

export enum AccountOpenHoursType {
  RECEIVING = "RECEIVING", SHIPPING = "SHIPPING", BUSINESS = "BUSINESS"
}

export const generateTimeOptions = (startTime?: string | null, endTime?: string | null) => {
  const times = [];
  let time = moment(startTime, "h:mm A");

  while (time.isSameOrBefore(moment(endTime, "h:mm A"))) {
      times.push({ label: time.format("h:mm A"), value: time.format("HH:mm:ss") });
      time.add(30, 'minutes');
  }

  return times;
}

export const getStandingAppointmentValidationSchema = (type: AccountOpenHoursType): Yup.ObjectSchema<StandingAppointmentDays> => {
  const dayValidation: Yup.ObjectSchema<StandingAppointmentHours> = Yup.object({
    fromTime: Yup.string().nullable()
    .when("isOpen", {
      is: (isOpen) => isOpen,
      then: Yup.string().required("Required") 
    }),
  toTime: Yup.string().nullable()
    .when("isOpen", {
      is: (isOpen) => isOpen,
      then: Yup.string().required("Required") 
    })
      .test("to-time-greater", "Invalid time", function (value){
        let fromTime = moment(this.parent.fromTime, "h:mm aa");
        let isOpen = this.parent.isOpen;
        if (!isOpen) return true;
        let toTime = moment(value, "h:mm aa");
        if (type === AccountOpenHoursType.BUSINESS) {
          return !this.parent.fromTime || toTime.isAfter(fromTime);
        } else {
          return !this.parent.fromTime || toTime.isSameOrAfter(fromTime.add(4, "hours"));

        }
      }),
    isOpen: Yup.boolean()
  }).defined();
  
  return Yup.object().shape({
    SUNDAY: dayValidation,
    MONDAY: dayValidation,
    TUESDAY: dayValidation,
    WEDNESDAY: dayValidation,
    THURSDAY: dayValidation,
    FRIDAY: dayValidation,
    SATURDAY: dayValidation
  }).defined();

}

