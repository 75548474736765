import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ContactCard } from "./contactCard";
import { Contact, ContactType } from "../../../app/data/contact/models";
import "./accountContact.scss";
import { AccountContactForm } from "./contactForm";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  addContact,
  deleteContact,
  editContact,
  getContacts,
  reset_team_account_contact_state,
  teamAccountContactSelector,
} from "../../../slices/customers/teams/teamAccountContactSlice";
import { toast } from "react-toastify";
import Loading from "../../../ui-components/loading/loading";
import ConfirmationModal from "../../../ui-components/confirmation-modal/confirmationModal";

export interface AccountContactProps {
  accountId: string;
}

const initialFormValues = {
  firstName: "",
  lastName: "",
  phone: "",
  title: "",
  email: "",
};

export const AccountContactPage: React.FC<AccountContactProps> = (props) => {
  const accountContactState = useSelector(teamAccountContactSelector);
  const [selectedContact, setSelectedContact] = useState<string | null>(null);
  const [newContact, setNewContact] = useState(false);
  const [contactForDelete, setContactForDelete] = useState<Contact | null>(null);

  const dispatch = useDispatch();

  const handleEditContactSubmit = (values: Contact) => {
    dispatch(
      editContact(
        props.accountId,
        values,
        () => {
          toast.info("Contact updated.");
          setSelectedContact(null);
        },
        () => toast.error("Something went wrong.")
      )
    );
  };

  const handleNewContactSubmit = (values: Contact) => {
    dispatch(
      addContact(
        props.accountId,
        values,
        () => {
          toast.info("Contact created.")
          setNewContact(false);
        },
        () => toast.error("Something went wrong.")
      )
    )
  }

  const handleDeleteContact = (contact: Contact) => {
    dispatch(
      deleteContact(
        props.accountId,
        contact,
        () => {
          toast.info("Contact deleted.");
          setContactForDelete(null);
        },
        () => toast.error("Something went wrong.")
      )
    )
  }

  const handleCancelEditing = () => {
    setNewContact(false);
    setSelectedContact(null);
  }

  const startEditContact = (id: string) => {
    setSelectedContact(id);
    setNewContact(false);
  };

  const openNewContactForm = () => {
    setNewContact(true);
    setSelectedContact(null);
  };

  useEffect(() => {
    dispatch(getContacts(props.accountId));
    return () => {
      dispatch(reset_team_account_contact_state());
    };
  }, [props.accountId, dispatch]);

  const renderContacts = (type: ContactType) => {
    const filteredContacts = accountContactState.contacts.filter(
      (contact) => contact.type === type
    );
    return (
      <div>
        {!filteredContacts.length && !newContact && <div className="xgs-account-contact__no-contacts">No contacts found.</div>}
        {filteredContacts.map((contact) => {
          return (
            <>
              {selectedContact !== contact.id && (
                <ContactCard
                  contact={contact}
                  onClickEdit={(id: string) => startEditContact(id)}
                  handleDelete={(contact: Contact) => setContactForDelete(contact)}
                />
              )}
              {selectedContact === contact.id && (
                <AccountContactForm
                  contacts={accountContactState.contacts}
                  onCancelEditing={handleCancelEditing} 
                  onSubmit={(values: Contact) => handleEditContactSubmit(values)} 
                  initialFormValues={contact} 
                  isEditing 
                  isSubmitting={accountContactState.request_was_started && !(accountContactState.request_creator === "GET")}
                />
              )}
              {}
            </>
          );
        })}
        {newContact && (
          <AccountContactForm
            contacts={accountContactState.contacts}
            onCancelEditing={handleCancelEditing}
            isEditing={false}
            initialFormValues={{ ...initialFormValues, type }}
            onSubmit={(values: Contact) => handleNewContactSubmit(values)}
            isSubmitting={accountContactState.request_was_started && !(accountContactState.request_creator === "GET")}
          />
        )}
        <Button
          disabled={newContact}
          className="xgs-account-contact__new-contact"
          theme={ButtonThemes.blue}
          onClick={openNewContactForm}
        >
          <XGSIcon icon={XGSIcons.faPlus} />&nbsp;&nbsp;&nbsp;&nbsp;Add new contact
        </Button>
      </div>
    );
  };

  const isFetchingContacts = accountContactState.request_creator === "GET" && accountContactState.request_was_started;
  const isDeleting = accountContactState.request_creator === "DELETE" && accountContactState.request_was_started;
  
  return (
    <div className="xgs-account-contact">
       {contactForDelete && <ConfirmationModal
          onCancel={() => setContactForDelete(null)} 
          onConfirm={() => handleDeleteContact(contactForDelete)} 
          opened={!!contactForDelete}
          spinner={isDeleting}
        >
          Are you sure you want to delete contact: <b>{contactForDelete.firstName} {contactForDelete.lastName}</b>?
        </ConfirmationModal>}
      <div className="xgs-account-contact__heading">Contacts</div>
      <div className="xgs-account-contact__sub-heading">Set contacts for all purposes</div>
      {isFetchingContacts && <Loading isLoading/>}
      {!isFetchingContacts && <Tabs>
        <TabList>
          <Tab>Receiving</Tab>
          <Tab>Shipping</Tab>
          <Tab>OS&D</Tab>
          <Tab>Finance</Tab>
        </TabList>
        <TabPanel>{renderContacts(ContactType.RECEIVING)}</TabPanel>
        <TabPanel>{renderContacts(ContactType.SHIPPING)}</TabPanel>
        <TabPanel>{renderContacts(ContactType.OSD)}</TabPanel>
        <TabPanel>{renderContacts(ContactType.PAYOR)}</TabPanel>
      </Tabs>}
    </div>
  );
};
