import React from "react";
import { Link } from "react-router-dom";
import { InventoryItemModel } from "../../../app/data/warehouse/models";

const inventoryListColumns = (
  accountNumber: number | null | undefined,
  onRowClick: (row: InventoryItemModel) => void
) => [
  {
    Header: "Item",
    accessor: "item",
    Cell: (cellProps: any) => {
      return cellProps.row.original.drillDown
        ? (
          <>
            <span
              className="xgs-warehouse__inventory__item xgs-warehouse__inventory__link"
              onClick={() => onRowClick(cellProps.row.original)}
            >
              {cellProps.value}
            </span>
            <div className="xgs-warehouse__tooltip">
              Style: {cellProps.row.original.style || "Not specified"}<br/>
              Color: {cellProps.row.original.color || "Not specified"}<br/>
              Location: {cellProps.row.original.location || "Not specified"}
            </div>
          </>
        )
        : (
          <>
            <span className="xgs-warehouse__inventory__item">
              {cellProps.value}
            </span>
            <div className="xgs-warehouse__tooltip">
              Style: {cellProps.row.original.style || "Not specified"}<br/>
              Color: {cellProps.row.original.color || "Not specified"}<br/>
              Location: {cellProps.row.original.location || "Not specified"}
            </div>            
          </>
        );
    },
    width: 120
  },
  {
    Header: "Probill",
    accessor: "invoiceNumber",
    width: 90,
    Cell: (cellProps: any) => (
      <Link
        className="xgs-table-link-cell"
        to={`${accountNumber ? "/" + accountNumber : ""}/shipments/${cellProps.value}`}
      >
        {cellProps.value}
      </Link>
    )
  },
  {
    Header: "BOL",
    accessor: "billOfLading"
  },
  {
    Header: "PO",
    accessor: "poNumber",
    width: 90
  },
  {
    Header: "Description",
    accessor: "description"
  },
  {
    Header: "Status",
    accessor: "status"
  },
  {
    Header: "Sq. Yards",
    accessor: "sqYards",
    width: 90
  },
  {
    Header: "Width",
    accessor: "width",
    width: 90
  },
  {
    Header: "Orig. Length",
    accessor: "originalLength",
    width: 90
  },
  {
    Header: "Curr. Length",
    accessor: "currentLength",
    width: 90
  }
];

export default inventoryListColumns;