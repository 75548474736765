import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface InventoryItemModel {
  item: string;
  drillDown: boolean;
  invoiceNumber: number;
  billOfLading: string;
  poNumber: string;
  description: string;
  status: string;
  sqYards: number;
  width: number;
  originalLength: number;
  currentLength: number;
  style: string;
  color: string;
  location: string;
}

export interface InventoryListRequestModel {
  item?: string;
  invoiceNumber?: number | string | undefined;
  lastIds?: string | null;
}

export const InventoryListRequestSchema: Yup.ObjectSchema<InventoryListRequestModel> = Yup.object({
  item: Yup.string().max(20, "The value must be no more than 20 characters"),
  invoiceNumber: Yup.number().min(10000, "The value must be no less than 5 digits").max(999999999, "The value must be no more than 9 digits").typeError(validationMessages.notValid)
}).defined();

export interface TransactionsItemModel {
  item: string;
  invoiceNumber: number;
  date: string;
  billOfLading: string;
  poNumber: string;
  description: string;
  sqYards: number;
  width: number;
  length: number;
}

export interface TransactionsListRequestModel {
  from: string;
  to: string;
  lastIds?: string | null;
}

export const TransactionsListRequestSchema: Yup.ObjectSchema<TransactionsListRequestModel> = Yup.object({
  from: Yup.string().required(validationMessages.required),
  to: Yup.string().required(validationMessages.required)
}).defined();

export interface ReceiptsItemModel {
  item: string;
  invoiceNumber: number;
  date: string;
  billOfLading: string;
  poNumber: string;
  description: string;
  sqYards: number;
  width: number;
  length: number;
}

export interface ReceiptsListRequestModel {
  from: string;
  to: string;
  lastIds?: string | null;
}
