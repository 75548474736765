import React, { useEffect, useMemo, useRef, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Formik, FormikProps } from "formik";
import XGSFormInput from "../../../ui-components/form/input/xgsFormInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import Button, {
  ButtonThemes
} from "../../../ui-components/button/button";
import { getInventoryList } from "../../../slices/warehouse/inventory/inventorySlice";
import { userSelector } from "../../../slices/user/userSlice";
import UserState from "../../../slices/user/UserState";
import InventoryState from "../../../slices/warehouse/inventory/InventoryState";
import { inventorySelector, resetInventoryState } from "../../../slices/warehouse/inventory/inventorySlice";
import FiltersToggleState from "../../../slices/filters-toggle/FiltersToggleState";
import { filtersToggleSelector } from "../../../slices/filters-toggle/filtersToggleSlice";
import { InventoryListRequestModel, InventoryListRequestSchema } from "../../../app/data/warehouse/models";
import "./../warehouse.scss";

const initialValues: InventoryListRequestModel = {
  item: "",
  invoiceNumber: "",
  lastIds: ""
};

const InventoryListFilter: React.FC<{}> = memo((props) => {
  const userState: UserState = useSelector(userSelector);
  const inventoryState: InventoryState = useSelector(
    inventorySelector
  );
  const filtersToggleState: FiltersToggleState = useSelector(
    filtersToggleSelector
  );  
  const searchFormRef = useRef<any>(null);
  const dispatch = useDispatch();

  const accountId = useMemo(() => {
    return userState.activeSubAccount?.id;
  }, [userState]);

  const onSearchSubmit = (data: InventoryListRequestModel) => {
    dispatch(resetInventoryState());
    accountId && dispatch(getInventoryList(accountId, data));
  };

  const onSearchClear = () => {
    searchFormRef.current.resetForm();
    searchFormRef.current.submitForm();
  };

  useEffect(() => {
    searchFormRef.current.setFieldValue("item", inventoryState.listRequest.item);
  }, [inventoryState.listRequest.item]);

  useEffect(() => {
    searchFormRef.current.resetForm();
    searchFormRef.current.submitForm();
  }, []);

  return (
    <div className={`xgs-warehouse__filter ${filtersToggleState.showFilters ? "xgs-filters-toggle__filters--show" : "xgs-filters-toggle__filters--hide"}`}>
      <Formik
        innerRef={searchFormRef}
        initialValues={initialValues}
        validationSchema={InventoryListRequestSchema}        
        onSubmit={onSearchSubmit}
      >
        {(props: FormikProps<InventoryListRequestModel>) => (
          <Form className="form-items">    
            <div className="xgs-warehouse__filter__controls">
              <div className="xgs-warehouse__filter__controls__item">
                <XGSFormInput
                  type="text"
                  name="item"
                  label="Item (roll, serial, etc.)"
                  required={false}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                />
              </div>
              <div className="xgs-warehouse__filter__controls__item">
                <XGSFormInput
                  type="text"
                  name="invoiceNumber"
                  label="Probill"
                  required={false}
                  requiredAsteriskDisabled={true}
                  labelMode={LabelModes.column}
                  className="xgs-warehouse__filter__input"
                />
              </div>
              <div className="xgs-warehouse__filter__controls__buttons">
                <Button
                  theme={ButtonThemes.blue}
                  type="submit"
                  disabled={!props.isValid}
                >
                  Search
                </Button>
                <Button
                  theme={ButtonThemes.gray}
                  type="button"
                  onClick={onSearchClear}
                >
                  Clear
                </Button>
              </div>        
            </div>
          </Form>
        )}
      </Formik>            
    </div>
  );
});

export default InventoryListFilter;
