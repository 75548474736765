import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import XGSErrorMessage from "../../../ui-components/error-message/errorMessage";
import Table from "../../../ui-components/table/table";
import Button, { ButtonThemes } from "../../../ui-components/button/button";
import LabeledSelectInput from "../../../ui-components/molecules/labeled-inputs/labeled-select-input/labeledSelectInput";
import { LabelModes } from "../../../ui-components/molecules/labeled-inputs/labeledInput";
import { XGSSelectOption } from "../../../ui-components/xgs-select/xgsSelect";
import XGSIcon from "../../../ui-components/icon/xgsIcon";
import XGSIcons from "../../../ui-components/icon/xgsIcons";
import Loading from "../../../ui-components/loading/loading";
import UserState from "../../../slices/user/UserState";
import { userSelector } from "../../../slices/user/userSlice";
import { UserUtils } from "../../../app/data/user/userUtils";
import { Routes } from "../../../app/route/RoutesConfig";
import BolState from "../../../slices/bol/BolState";
import {
  bolSelector,
  clearCsvLink,
  getBols,
  getBolCsv,
  resetBolState
} from "../../../slices/bol/bolSlice";
import BolDetails from "./details";
import { BOL_TYPES } from "../constants";
import "../bol.scss";

interface BolListProps {
  defaultTypes: string[];
};

const BolList: React.FC<BolListProps> = (props) => {
  const { defaultTypes } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const userState: UserState = useSelector(userSelector);
  const bolState: BolState = useSelector(bolSelector);
  const [bolDetailsProbill, setBolDetailsProbill] = useState<string | undefined>(undefined);
  const [types, setTypes] = useState<string[]>(!UserUtils.hasFeatureDisabled(userState.profile, "RETURNS") ? defaultTypes : defaultTypes.filter(type => type !== "RETURN"));

  const columns = [
    {
      width: 90,
      Header: "Probill #",
      accessor: "probill",
      Cell: (cellProps: any) => (
        <Link
          className="xgs-table-link-cell"
          target="_blank"
          to={`${userState.activeSubAccount?.accountNumber ? "/" + userState.activeSubAccount?.accountNumber : ""}/shipments/${cellProps.value}`}
        >
          {cellProps.value}
        </Link>
      )
    },
    {
      width: 100,
      Header: "Status",
      accessor: "status.translate"
    },
    {
      width: 70,
      Header: "Type",
      accessor: "bolType",
      Cell: (cellProps: any) => (
        <>
          { cellProps.value === "BOL" ? "BOL" : "" }
          { cellProps.value === "RETURN" ? "Return" : "" }
        </>
      )
    },
    {
      width: 140,
      Header: "Shipper",
      accessor: "shipper",
      Cell: (cellProps: any) => (
        <>
          { cellProps.value === 69 ? cellProps.row.original.shipperInfo?.name : cellProps.value }
        </>
      )
    },
    {
      width: 160,
      Header: !UserUtils.hasFeatureDisabled(userState.profile, "RETURNS") ? "Return Auth./BOL #" : "BOL #",
      accessor: "bol"
    },
    {
      Header: "PO #",
      accessor: "po",
      width: 130
    },
    {
      Header: "Consignee",
      accessor: "consignee.name",
      width: 210
    },
    {
      Header: "City",
      accessor: "consignee.address.city",
      width: 130
    },
    {
      Header: (
        <div className="text-center">
          State
        </div>
      ),
      accessor: "consignee.address.state",
      width: 55,
      Cell: (cellProps: any) => (
        <div className="text-center">
          { cellProps.value }
        </div>
      )
    },
    {
      Header: "Phone",
      accessor: "consignee.phone",
      width: 115
    },
    {
      Header: "Date",
      accessor: "dateCreated",
      width: 95,
      Cell: (cellProps: any) => (
        <>
          { cellProps.value.toUiDateFormat() }
        </>
      )
    },
    {
      Header: "Created By",
      accessor: "createdBy",
      width: 120
    }
  ];

  const onFilterChange = (types: XGSSelectOption[] | null | undefined) => {
    const typesArr = types ? types.map(obj => obj.value) : [];
    dispatch(getBols(typesArr));
    dispatch(clearCsvLink());
    setTypes(typesArr);
  };

  const getFilterValue = () => {
    let value: XGSSelectOption[] = [];
    for (let type of types) {
      const typeObj = BOL_TYPES.find(obj => obj.value === type);
      typeObj && value.push(typeObj);
    }
    return value;
  };

  const onBolsInfiniteScroll = () => {
    dispatch(getBols(types, bolState.bolsRequest?.lastIds));
  };

  const onBolClick = (row: any) => {
    setBolDetailsProbill(row.probill)
  };

  const fetchCSV = () => {
    if (bolState.csvLink) {
      onExportSuccess(bolState.csvLink);
    } else {
      dispatch(getBolCsv(types, onExportSuccess));
    }
  };

  const onExportSuccess = (exportLink: string) => {
    if (!exportLink) return
    const link = document.createElement("a");
    link.href = exportLink;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    dispatch(resetBolState());
    dispatch(getBols(types));

    return () => {
      dispatch(resetBolState());
      dispatch(clearCsvLink());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return (
    <div className="xgs-bol__list">
      <div
        className="xgs-bol__list__download-csv xgs-site__content-container__toolbar__buttons__item"
        onClick={() => {
          !(bolState.requestStarted && bolState.requestCreator === "DOWNLOAD_CSV") && fetchCSV();
        }}
      >
        {!(bolState.requestStarted && bolState.requestCreator === "DOWNLOAD_CSV") && (
          <XGSIcon
            icon={XGSIcons.faFileDownload}
            size="lg"
            className="xgs-site__content-container__toolbar__buttons__item__icon"
          />
        )}
        {bolState.requestStarted && bolState.requestCreator === "DOWNLOAD_CSV" && (
          <Loading
            isLoading={bolState.requestStarted}
            className="xgs-site__content-container__toolbar__buttons__item__icon xgs-bol__list__download-csv__spinner"
          />
        )}
        <span className="xgs-site__content-container__toolbar__buttons__item__text xgs-site__content-container__toolbar__buttons__item__text--w-icon">Download CSV</span>
      </div>
      <div className="xgs-bol__list__top">
        <div className="xgs-bol__list__top__buttons">
          {defaultTypes.find(type => type === "BOL") && (
            <Button
              theme={ButtonThemes.blue}
              onClick={() => history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.bol.create}`)}
            >
              Create Bill of Lading
            </Button>
          )}
          {defaultTypes.find(type => type === "PICKUP") && (
            <Button
              theme={ButtonThemes.blue}
              onClick={() => history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.pickup.create}`)}
            >
              Create Pickup
            </Button>
          )}
          {defaultTypes.find(type => type === "RETURN") && !UserUtils.hasFeatureDisabled(userState.profile, "RETURNS") && (
            <Button
              theme={ButtonThemes.blue}
              onClick={() => history.push(`/${userState.activeSubAccount?.accountNumber}${Routes.return.create}`)}
            >
              Create Return
            </Button>
          )}
        </div>
        <div className="xgs-bol__list__top__filter">
          <LabeledSelectInput
            onMultiValuesChange={(option) => onFilterChange(option)} 
            options={!UserUtils.hasFeatureDisabled(userState.profile, "RETURNS") ? BOL_TYPES : BOL_TYPES.filter(type => type.value !== "RETURN")}
            label="Filter:"
            labelMode={LabelModes.column}
            isMulti
            isClearable
            disabled={false}
            value={getFilterValue()}
          />
        </div>
      </div>
      {bolState.fetchFailed && bolState.fetchError && (
        <XGSErrorMessage>{bolState.fetchError}</XGSErrorMessage>
      )}
      {!bolState.fetchFailed && (
        <>
          <Table
            isLoading={bolState.fetchStarted}
            columns={columns}
            data={bolState.bols}
            cursorPointer={true}
            onRowClicked={onBolClick}
            rowHeight={58}
            minTableHeight={200}
            noResultsText="There are no items"
            infiniteScroll={true}
            infiniteScrollLoading={bolState.fetchPortionStarted}
            infiniteScrollHasNext={!bolState.bolsFetchedAll}
            onInfiniteScroll={onBolsInfiniteScroll}
          />
          <BolDetails
            probill={bolDetailsProbill}
            show={!!bolDetailsProbill}
            onClose={() => {
              setBolDetailsProbill(undefined);
            }}
          />
        </>
      )}
    </div>
  );
};

export default BolList;
