import * as Yup from "yup";
import { validationMessages } from "../common/validationMessages";

export interface Contact {
  id?: string;
  firstName: string;
  lastName: string;
  type: ContactType;
  title: string;
  email: string;
  phone: string;
}

export enum ContactType {
  OSD = "OSD",
  RECEIVING = "RECEIVING",
  SHIPPING = "SHIPPING",
  PAYOR ="PAYOR"
}

export const AccountContactModel = Yup.object({
  firstName: Yup.string().matches(/\S+/, validationMessages.notValid).max(128, "Maximum 128 characters").required(validationMessages.required),
  lastName: Yup.string().matches(/\S+/, validationMessages.notValid).max(128, "Maximum 128 characters").required(validationMessages.required),
  title: Yup.string().matches(/\S+/, validationMessages.notValid).max(60, "Maximum 60 characters").required(validationMessages.required),
  phone: Yup.string().matches(/^\(\d{3}\)\s\d{3}-\d{4}$/, validationMessages.notValid).required(validationMessages.required),
  email: Yup.string().max(60, "The value must be no more than 60 characters").email(validationMessages.emailNotValid).required(validationMessages.required),
  type: Yup.string().required(validationMessages.required)
})